

















































import {
  SfMenuItem, SfList, SfBar,
} from '@storefront-ui/vue';

import {
  defineComponent,
  useContext,
  useRouter,
  useRoute,
  computed,
  useFetch,
} from '@nuxtjs/composition-api';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';
import { useUser } from '../../composables/useUser';

export default defineComponent({
  name: 'MyAccount',
  components: {
    SfBar,
    SfList,
    SfMenuItem,
  },
  middleware: 'is-authenticated',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { i18n, localeRoute } = useContext();
    const { user, load: loadUser } = useUser();

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const { sidebarLinkGroups, logoutUser } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const goToTopLevelRoute = () => router.push(localeRoute({ name: 'customer' }));
    const title = computed(() => i18n.t(route.value.matched.at(-1)?.meta.titleLabel as string));

    /**
     * #tab-id: handler-name
     */
    const handlers = {
      'log-out': logoutUser,
    };

    const getHandler = (id: string) => handlers[id] ?? {};

    return {
      sidebarLinkGroups,
      title,
      isOnSubpage,
      goToTopLevelRoute,
      logoutUser,
      getHandler,
    };
  },
});
